<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";

/**
 * Security Component
 */
export default {

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {
      logins: [],

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,
      },

      inputPage: "",

      submitted: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      }
    };
  },

  validations: {
    form: {
      oldPassword: {required},
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function (value) {
          return /\W/.test(value)
        }
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    }
  },


  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  methods: {

    deleteSession(id) {
      const json = JSON.stringify({
        "id": id,
      });

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: this.$t('alert.title'),
        text: this.$t('alert.message'),
        icon: "warning",
        confirmButtonText: this.$t('alert.yes'),
        cancelButtonText: this.$t('alert.no'),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          axios.delete(`/user/session`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: json
          }).then(() => {
            swalWithBootstrapButtons.fire(
                this.$t('message.deleted'),
                this.$t('session.logout'),
                "success"
            );

            setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
          }).catch((error) => {
            errorCatcher.catchErrors(error)
            this.submitted = false;
          });
        }
      });
    },

    logoutOther() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: this.$t('alert.title'),
        text: this.$t('alert.message'),
        icon: "warning",
        confirmButtonText: this.$t('alert.yes'),
        cancelButtonText: this.$t('alert.no'),
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          axios.delete(`/user/session/delete-other`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            },
            data: {}
          }).then(() => {
            swalWithBootstrapButtons.fire(
                this.$t('message.deleted'),
                this.$t('session.logout-other'),
                "success"
            );

            setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
          }).catch((error) => {
            errorCatcher.catchErrors(error)
            this.submitted = false;
          });
        }
      });
    },

    changePassword() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "oldPassword": this.form.oldPassword,
        "user": {
          "password": this.form.newPassword
        }
      });

      axios.post(`/user/profile`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.submitted = false;

        this.$bvToast.toast(this.$t('security.password-changed-successfully'), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.form = {};
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('security.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        // { key: "id", sortable: true, label: "ID" },
        { key: "ipAddress", label: this.$t('table.ip') },
        { key: "device", label: this.$t('table.device') },
        {
          key: "createdAt", label: this.$t('table.logging'), formatter: value => {
            const dateOptions = { timeZone: 'Europe/Warsaw', weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', seconds: 'numeric' };

            const dateFormatter = new Intl.DateTimeFormat(this.$store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
            return dateFormatter.format(new Date(value));
          }
        },
        { key: "action", label: this.$t('table.delete') }
      ]
    }

  },

  async created() {
    try {
      const result = await axios.get(`/user/session`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.logins = result.data
      this.table.totalRows = this.logins.length;
      this.table.rows = this.logins.length
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="$t('security.title')" :items="getItems()" />
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ $t('security.changePassword') }}</h4>

        <div class="row">
          <div class="col-md-8">
            <form class="needs-validation">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="password">{{  $t('message.password') }}</label>
                    <input
                        id="password"
                        v-model="form.oldPassword"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': this.submitted && $v.form.oldPassword.$error }"/>
                    <div v-if="submitted && $v.form.oldPassword.$error" class="invalid-feedback">
                      <span v-if="!$v.form.oldPassword.required">{{ $t('message.required')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="newPassword">{{ $t('security.newPassword') }}</label>
                    <input
                        id="newPassword"
                        v-model="form.newPassword"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.newPassword.$error }"/>
                    <div v-if="submitted && !$v.form.newPassword.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                    <div v-if="submitted && !$v.form.newPassword.minLength" class="invalid-feedback">{{ $t('register.password-min-length')}}</div>
                    <div v-if="submitted && !$v.form.newPassword.containsUppercase" class="invalid-feedback">{{ $t('register.password-uppercase')}}</div>
                    <div v-if="submitted && !$v.form.newPassword.containsLowercase" class="invalid-feedback">{{ $t('register.password-lowercase')}}</div>
                    <div v-if="submitted && !$v.form.newPassword.containsNumber" class="invalid-feedback">{{ $t('register.password-numeric')}}</div>
                    <div v-if="submitted && !$v.form.newPassword.containsSpecial" class="invalid-feedback">{{ $t('register.password-special')}}</div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="confirmNewPassword">{{ $t('security.confirmNewPassword') }}</label>
                    <input
                        id="confirmNewPassword"
                        v-model="form.confirmNewPassword"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': submitted && $v.form.confirmNewPassword.$error }"/>
                    <div v-if="submitted && !$v.form.confirmNewPassword.required" class="invalid-feedback">{{ $t('message.required')}}</div>
                    <div v-if="submitted && !$v.form.confirmNewPassword.sameAsPassword && $v.form.confirmNewPassword.required" class="invalid-feedback">{{ $t('register.samePassword')}}</div>
                  </div>
                </div>
              </div>

              <b-button @click="changePassword" variant="primary" class="w-sm ml-1">{{  $t('message.save') }}</b-button>
            </form>
          </div>
        </div>

        <div class="py-4">
        <h4 class="card-title mb-4">{{ $t('security.table.title') }}</h4>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                {{ $t('table.show') }}&nbsp;
                <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
              </label>
            </div>
          </div>

          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
              <b-button variant="primary" @click="logoutOther" class="mb-2 mx-2">{{ $t('security.logout-other') }}</b-button>

              <label class="d-inline-flex align-items-center">
                {{ $t('table.search') }}
                <b-form-input
                    v-model="table.filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>

          <div class="row">
            <div class="col">
              <div class="float-right">
                <label class="d-inline-flex align-items-center mx-2">
                  <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                </label>
                <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
              </div>
            </div>
          </div>

          <div class="row py-3">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>

        <div class="table-responsive">
          <b-table

              :items="logins"
              :fields="getFields()"
              responsive="sm"
              :per-page="table.perPage"
              :current-page="table.currentPage"
              :sort-by.sync="table.sortBy"
              :sort-desc.sync="table.sortDesc"

              :filter-included-fields="table.filterOn"
              @filtered="elements => paginationHelper.onFiltered(table, elements)">
            <template v-slot:cell(action)="data">
              <a @click="deleteSession(data.item.id)" class="clickable-element text-danger">
                <i class="mdi mdi-trash-can font-size-18"></i>
              </a>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="float-left">
              <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
            </div>

            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </Layout>
</template>